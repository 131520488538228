@font-face {
  font-family: 'LiquidAmber';
  src: local('LiquidAmber'), url(./fonts/LiquidAmber.otf) format('opentype');
}

@font-face {
  font-family: 'GothamRounded-Bold';
  src: local('GothamRounded-Bold'),
    url(./fonts/GothamRounded-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'GothamRounded-Light';
  src: local('GothamRounded-Light'),
    url(./fonts/GothamRounded-Light.otf) format('opentype');
}

@font-face {
  font-family: 'GothamRounded-Medium';
  src: local('GothamRounded-Medium'),
    url(./fonts/GothamRounded-Medium.otf) format('opentype');
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  cursor: pointer;
}
